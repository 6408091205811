.search-input {
    height: 50px;
}

.col-other-filters {
    border-radius: 10px;
    border: solid #4d5b75 1px; 
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.col-filters {
    border-radius: 10px;
    border: solid #e6ebf1 1px; 
    height: 60px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-left: 10px;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-main {
    text-align: center;
    font-size: 24px; 
    color: #455560;
}

.back {
   font-size: 24px; 
   color: #E4002B; 
   margin-right: 10px;
}

.card-min {
    min-width: 400px;
    margin-top: 20px;
}